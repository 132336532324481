
import React, {useEffect, useState, useContext} from 'react';
import { VectorMap } from "react-jvectormap"

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';


import './WorldMapChart.css';

const WolrdMapChart = () => {

    const { isLoading, sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);

    const [rawStats, setRawStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [countryData, setCountryData] = useState([]);
   

    function removeDuplicates(originalArray, arrayKeys) {
        const filtered = originalArray.filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(arrayKeys.map(k => o[k]).join('|'))
          )(new Set())
        );
    
        console.log(filtered);
        return filtered;
      }

      useEffect(() => {
        const fetchStats = async () => {
          try {
            const responseData = await sendRequest(
              `https://hansa.api.eventstreams.co/api/stats/visitors-loc`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );      
            if (responseData.stats ) {
                console.log(responseData.stats);
              if (responseData.stats.length !== rawStats.length){
                setRawStats(responseData.stats);
                setFilteredStats(removeDuplicates(responseData.stats, ['userId', 'countryCode']));
              }
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchStats();
      }, []);

      useEffect(() => {

        var countryStatData = []
     
         filteredStats.map(item => (  
            item.countryCode && countryStatData.push(item.countryCode)
         ))
  
         const counts = {};
         countryStatData.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

         console.log(countryStatData);
         console.log(counts);
         setCountryData(counts)
     
       }, [filteredStats]);

   
    const objectArray = Object.entries(countryData);

    objectArray.forEach(([key, value]) => {
    console.log(key); // 'CN'
    console.log(value); // 1000
    });


    const show = (e, el, code) => {

        if (countryData[code] > 0 ) {
            el.html(el.html()+' (unique visits - '+ countryData[code] +')');
        } else {
            el.html(el.html()+' (no visitors)');
        }
        
       

      
    }

    return (
        <div>
        {!isLoading ? <VectorMap
        map={"world_mill"}
        backgroundColor="transparent" 
        zoomOnScroll={false}
        containerStyle={{
          width: "100%",
          height: "520px"
        }}
        regionsSelectableOne={false} 
        onRegionTipShow={show}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e5e5e5",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0
          },
          hover: {
            "fill-opacity": 0.8,
            fill: "#EA8900",
            cursor: "pointer",

          },
          selected: {
            fill: "#EA8900"
          },
          selectedHover: {
            fill: "#EA8900"
          }
        }}
        regionsSelectable={true}
       
        series={{
          regions: [
            {
              values: countryData,
              scale: ["#B6ACD2","#A99CC9", "#9B8DBF", "#8E7EB5","#8170AA","#74629F","#675494","#5A4689","#4D397D","#463179","#402A74", "#39236E","#331D68","#2D1861","#28135A","#220E52", "#1D0B49"], 
              normalizeFunction: "polynomial"
            }
          ]
        }}
        
      />
      : 
      <h1>Loading data...</h1>
      }

    </div>

        
    )
}

export default WolrdMapChart;