import React, { useState, useContext } from 'react';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import Input from '../../shared/components/FormElements/Input';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';

import './AddVisitor.css';

import Button from '../../shared/components/UIElements/Buttons/Button';

const AddVisitor = ({ update }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();
    setShowSuccess(false);
    const email = event.target.email.value;

    try {
      const responseData = await sendRequest(
        `https://hansa.api.eventstreams.co/api/user/create`,
        'POST',
        JSON.stringify({
          email: email,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      if (responseData.user) {
        setShowSuccess(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section id="add-visitor">
      <h1>Register New Visitor</h1>

      <ErrorModal
        error={error}
        className={'fm-modal-sm'}
        onClear={clearError}
      />

      {isLoading && <LoadingSpinner />}

      {!isLoading && (
        <form onSubmit={onSubmit}>
          <div className="input-grouping">
            <div className="input-with-error__wrapper">
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="required"
                groupFront="Email:"
              />
            </div>
          </div>
          {showSuccess && (
            <div>
              <p className="added-success">Added Succesfully</p>
            </div>
          )}

          <div className="update-port-day-actions">
            <Button className="button-primary" type="submit" title="submit" />
          </div>
        </form>
      )}
    </section>
  );
};

export default AddVisitor;
