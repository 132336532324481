import React from 'react';
import { motion } from 'framer-motion';

import Item from './Item';

import './ItemsList.css';

const ItemsList = ({ categoryTitle, items, isLoading, selectedItem }) => {

 



  return (
    <section id="item-list">
      <h2 className='item-list-h2'>{categoryTitle}</h2>
      <div className='slider_containter'>
     
          {items.map(item  => (
            <motion.div
                key={item.id}
                className='ws-items__inner-wrapper'
                layoutId={item.id}
                src={item.src}
                onClick={() => selectedItem(item)}
                whileHover={{ y: -20 }}
              >
              <Item item={item} />
            </motion.div>
          ))}

      </div>
    </section>
  );
};

export default ItemsList;
