import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import Input from '../../shared/components/FormElements/Input';
import Card from '../../shared/components/UIElements/Card';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import VisitorItem from './VisitorItem';

import './VisitorList.css';

const VisitorList = ({ selectVisitorHandler }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const auth = useContext(AuthContext);
  const [visitors, setVisitors] = useState([]);
  const [visitorList, setVisitorList] = useState([]);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const responseData = await sendRequest(
          `https://hansa.api.eventstreams.co/api/user`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: auth.token,
          }
        );

        setVisitors(responseData.visitors);
        console.log(responseData.visitors);
      } catch (err) {
        console.log(err);
      }
    };
    fetchVisitors();
  }, [sendRequest]);

  useEffect(() => {
    setVisitorList(visitors);
  }, [visitors]);

  const submitHandler = event => {
    event.preventDefault();
    let result;

    if (event.target.email.value) {
      if (result) {
        const update = visitors.filter(v =>
          v.email.includes(event.target.email.value)
        );
        if (update.length > 0) {
          result = [...result, ...update];
        }
      } else {
        result = visitors.filter(v =>
          v.email.includes(event.target.email.value)
        );
      }
    }

    if (!result) {
      setVisitorList(visitors);
    } else if (result.length > 0) {
      setVisitorList(result);
    }
  };

  const selectHandler = id => {
    selectVisitorHandler(id);
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center spinner-margin-top">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading ? (
        <Card className={'visitor-list-card'}>
          <div className="visitor-list-content">
            <div className="visitor-list-filter">
              <form onSubmit={submitHandler}>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="email"
                  className="search-input  no-group"
                  wrapperClassName="input-search-wrapper"
                />

                <button className="search-button" type="submit">
                  filter
                </button>
              </form>
            </div>
            <div className="visitor-list">
              {visitorList.length === 0 ? (
                <h2>No Visitors found</h2>
              ) : (
                visitorList.map(visitor => (
                  <VisitorItem
                    key={visitor.id}
                    visitor={visitor}
                    selectedVisitor={selectHandler}
                  />
                ))
              )}
            </div>
          </div>
        </Card>
      ) : null}
    </>
  );
};

export default VisitorList;
