import React, { useEffect, useState, useContext } from 'react'
import {Doughnut} from 'react-chartjs-2';

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';

import './PieChart.css';

const PieChart = () => {

    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    
    const [rawStats, setRawStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [browserType, setBrowserTypes] = useState([]);
    const [browserTypeCount, setBrowserTypeCount] = useState([]);

    function removeDuplicates(originalArray, arrayKeys) {
        const filtered = originalArray.filter(
          (
            s => o =>
              (k => !s.has(k) && s.add(k))(arrayKeys.map(k => o[k]).join('|'))
          )(new Set())
        );
    
        console.log(filtered);
        return filtered;
      }

      useEffect(() => {
        const fetchStats = async () => {
          try {
            const responseData = await sendRequest(
              `https://hansa.api.eventstreams.co/api/stats/browser`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );      
            if (responseData.stats ) {
              if (responseData.stats.length !== rawStats.length){
                setRawStats(responseData.stats);
                setFilteredStats(removeDuplicates(responseData.stats, ['userId', 'browserName']));
              }
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchStats();
      }, []);

      useEffect(() => {

        var browserList = []
     
         filteredStats.map(item => (  
            browserList.push(item.browserName)
         ))
  
         const counts = {};
         browserList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
        
         const deviceNames = Object.keys(counts);
         const deviceNumbers = Object.values(counts);
         setBrowserTypes(deviceNames);
         setBrowserTypeCount(deviceNumbers)
     
       }, [filteredStats]);
    

    return (
        <div className='pie-chart-container'>
        
            <Doughnut 
                data={{
                    labels: browserType,
                    datasets: [{
                        data: browserTypeCount,
                        backgroundColor: [
                        '#C5C4D6',
                        '#A6A2BF',
                        '#8780A9',
                        '#695D93',
                        '#4D397D',
                        '#41316F',
                        '#352A62',
                        '#2A2253',
                        '#201B45',
                         '#171536',
                        ],
                        borderColor: 
                            'white',
                        borderWidth: 0
                    }]
                }}
                
                
                options={{
                    maintainAspectRatio : false
                }}
            />
        </div>
    )
}

export default PieChart;