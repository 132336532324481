import React from 'react'
import { NavLink } from 'react-router-dom'; 



import './mainNav.css';

 const MainNav = () => {


    return (
        <div className="main-nav__wrapper">
            <ul className="nav-links">
                <li>
                    <NavLink to="/">Stats</NavLink>
                </li>
                <li>
                    <NavLink to="/visitors">Visitors</NavLink>
                </li>
                <li>
                    <NavLink to="/videos">Videos</NavLink>
                </li>
            </ul>
 
        </div>
    )
}


export default MainNav;