import React, { useEffect, useState, useContext } from 'react';
import {Bar} from 'react-chartjs-2';

import { AuthContext } from '../../../shared/context/auth-context';
import { useHttpClient } from '../../../shared/hooks/http-hook';

import './BarChart.css';

const BarChart = props => {

    const { sendRequest } = useHttpClient();

    const auth = useContext(AuthContext);
    const [rawStats, setRawStats] = useState([]);
    const [filteredStats, setFilteredStats] = useState([]);
    const [videoTitle, setVideoTitle] = useState([]);
    const [loadcount, setLoadCount] = useState([]);
    const [endCount, setEndCount] = useState([]);

    useEffect(() => {
        const fetchStats = async () => {
          try {
            const responseData = await sendRequest(
              `https://hansa.api.eventstreams.co/api/stats/video`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );      
            if (responseData.stats ) {
              if (responseData.stats.length !== rawStats.length){
               
                console.log(responseData.stats);

                const stats = responseData.stats
                var titleArry = [];
                var loadArry = [];
                var endArry = [];

                for (var i = 0; i < stats.length; i++) {
                    
                    titleArry.push(stats[i].title);
                    endArry.push(stats[i].onEndCount);
                    loadArry.push(stats[i].onLoadCount);

                }

               setVideoTitle(titleArry);
               setLoadCount(loadArry);
               setEndCount(endArry);
                
              }
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchStats();
      }, []);
    
  

    return (
        <div className='bar-chart-container'>
        
            <Bar 
                data={{
                    labels: videoTitle,
                    datasets: [
                        {
                        label: '# of page loads',
                        data: loadcount,
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1

                        },
                        {
                        label: '# videos watched to end',
                        data: endCount,
                        backgroundColor: 'rgba(153, 102, 255, 0.6)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1
                        },
                    ]
                }}
                
                
                options={{
                    maintainAspectRatio : false
                }}
            />
        </div>
    )
}

export default BarChart;