
import VimeoPlayer from '../../shared/components/Video/VimeoPlayer';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory} from 'react-router-dom';

import ItemList from '../components/ItemsList';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import TotalDuration from '../../shared/components/UIElements/TotalDuration';
import Time from '../../shared/components/UIElements/Time';

import Header from '../../shared/components/header/Header';
import './Videos.css';

const Videos = () => {

    const { REACT_APP_API_URL } = process.env;
    const history = useHistory();
    const [items, setItems] = useState();
    const [item, setItem] = useState();
    const [videoId, setVideoId] = useState();
    const [duration, setDuration] = useState(0)
    const [current, setCurrent] = useState(`0 %`)
    const [updateItem, toggleUpdateItem] = useState(false);

    const { isLoading, sendRequest } = useHttpClient();
    const { token, isLoggedIn } = useContext(AuthContext);

    useEffect(() => {
        if(item) {
            setVideoId(item.videoId); 
            setDuration(`${item.duration / 60} mins`);
        }
        
     
    }, [updateItem]);
  
    useEffect(() => {
      if (isLoggedIn) {
      const fetchItems = async () => {
        try {
          const responseData = await sendRequest(
            `https://hansa.api.eventstreams.co/api/event-item/all`,
            'POST',
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
              }
          );
          if(responseData.eventItems) {
            setItems(responseData.eventItems)
            console.log(responseData.eventItems);
          }
         
        } catch (err) {
          console.log(err);
        }
      };
      fetchItems();
    } else {
      history.push("/auth");
    }
      
    }, [])
  
    const time = (event) => {
      console.log(event);
  
      const totalDuration = (event.duration / 60)
      const currentPosition = (event.percent * 100)
  
      setDuration(`${Math.floor(totalDuration)} mins`)
      setCurrent(`${Math.floor(currentPosition)} %`)
      
    }
  
    const pageVariants = {
      initial: {
        opacity: 0,
        duration: 5,
        x: -200,
      },
      in: {
        opacity: 1,
        duration: 2,
        x: 0,
      },
      out: {
        opacity: 0,
        duration: 5,
        x: -200,
      },
    };
  
    const selectedItem = selcetedItem => {

        setItem(selcetedItem);
        toggleUpdateItem(updateItem ? toggleUpdateItem(false): toggleUpdateItem(true));
    }
  


    return (
        <div className="page__wrapper">
        <Header title={'Video Content'} />
        
        <div className="page-content clear-header">

        {item ? 
      <div className='event-item-video-container'>
        <div className="item-video">
            
            {videoId ? <VimeoPlayer videoId={item.videoId} time={time} /> : <div>Video Not found</div> }
          
        </div>
      </div>
      :
      <div className='center'><h1>Video not found</h1></div>
      }

      {item && <div className='event-item-content-body'>
          <div className='event-item-content-info'>
                <h1 className='item-title'>{item.title}</h1>
                <h2 className='item-location'>{item.subTitle}</h2>
               
                <div className='video-info-container'>
                  <Time time={item.time}/>
                  <TotalDuration title='Duration' duration={duration} />
                  <TotalDuration title='Progress' duration={current} />
                </div>
          </div>
      </div>
    }
    {items && !isLoading && <ItemList categoryTitle={'Videos'} items={items} selectedItem={selectedItem} />}
    {isLoading && <div className='center'><LoadingSpinner /></div>}
      </div>
        
      
        
      </div>
    )
}

export default Videos;
