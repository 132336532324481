import React, { Fragment } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import './VideoPlayer.css';

const VimeoPlayer = ({ videoId, time }) => {
  return (
    <Fragment>
      <Vimeo video={videoId} responsive={true} onTimeUpdate={time} />
    </Fragment>
  );
};

export default VimeoPlayer;
