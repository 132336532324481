import React, {useState}  from 'react';

import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import AddVisitor from '../components/AddVisitor';

import VisitorList from '../components/VisitorList';
import './Visitors.css';


const shipsMenu = [
  {
    id: 0,
    title: 'list'
  },
  {
    id: 1,
    title: 'add visitor'
  }
]

const Ships = props => {
  
  const [showVisitorList, setShowVisitorList] = useState(true);
  const [showAddVisitor, setShowAddVisitor] = useState(false);
  const [shipId, setShipId] = useState();

  const selectVisitorHandler = id => {
    
    console.log(id);
   
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
       
        setShowAddVisitor(false);
        setShowVisitorList(true);        
        break;
      case 1:
          
          setShowAddVisitor(true);
          setShowVisitorList(false);        
        break;
    
      default:
        break;
    }
  }
 

  
  return (
    <div className="page__wrapper">
    <Header title={'Visitors'} />
    <SubMenu items={shipsMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
      {showVisitorList && <VisitorList selectVisitorHandler={selectVisitorHandler} />}
      {showAddVisitor && <AddVisitor />}
  
    </div>
  </div>
  );
};

export default Ships;
