import React, { useState, Fragment, useEffect, useContext } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import './VisitorItem.css';
import Button from '../../shared/components/UIElements/Buttons/Button';

const VisitorItem = ({ visitor, selectedVisitor }) => {
  const auth = useContext(AuthContext);
  const [showDetails, setShowDetails] = useState(false);
  const [ticketNo, setTicketNo] = useState('');
  const [emailStatus, setEmailStatus] = useState('Resend ticket email');
  const [isSuspended, setIsSuspended] = useState();
  const [registered, setRegistered] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    if (visitor.ticketNo) {
      setTicketNo(visitor.ticketNo);
    } else {
      setTicketNo('No Ticket Assigned');
    }

    if (visitor.suspended) {
      setIsSuspended(true);
    } else {
      setIsSuspended(false);
    }

    if (visitor.registered) {
      setRegistered(true);
    }
  }, [visitor]);

  const rollTicket = async id => {
    try {
      const responseData = await sendRequest(
        `https://hansa.api.eventstreams.co/api/user/roll-ticket`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token,
        }
      );

      if (responseData) {
        console.log(responseData.result);
        setTicketNo(responseData.result.ticketNo);
        setIsSuspended(false);
        setRegistered(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resendTicket = async id => {
    try {
      const responseData = await sendRequest(
        `https://hansa.api.eventstreams.co/api/user/resend-ticket-email`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token,
        }
      );
      console.log(responseData);
      if (responseData.message) {
        setEmailStatus('email resent');
      } else {
        console.log(responseData.mesage);
        setEmailStatus('Error - not sent');
      }
    } catch (err) {
      setEmailStatus('Error - not sent');
      console.log(err);
    }
  };

  const suspend = async id => {
    try {
      const responseData = await sendRequest(
        `https://hansa.api.eventstreams.co/api/user/suspend-user`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token,
        }
      );
      console.log(responseData);
      if (responseData.success) {
        setIsSuspended(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const unsuspend = async id => {
    try {
      const responseData = await sendRequest(
        `https://hansa.api.eventstreams.co/api/user/unsuspend-user`,
        'POST',
        JSON.stringify({
          userId: id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: auth.token,
        }
      );
      console.log(responseData);
      if (responseData.success) {
        setIsSuspended(false);
      }
    } catch (err) {
      setEmailStatus('Error - not sent');
      console.log(err);
    }
  };

  const selected = () => {
    showDetails ? setShowDetails(false) : setShowDetails(true);
  };

  return (
    <Fragment>
      <div
        className="visitor-item__wrapper"
        onClick={() => selected(visitor.id)}
      >
        <div>
          {registered ? (
            <span className="vi-registered">
              <FontAwesomeIcon icon="check-circle" />
            </span>
          ) : (
            <span className="vi-unregistered">
              <FontAwesomeIcon icon="times-circle" />
            </span>
          )}
        </div>
        <div className="email-box">
          <h2 className="visitor-item-email">{visitor.email}</h2>
        </div>
        <div className="last-logged-box">
          <h2 className="visitor-item-last-logged">
            Last Logged in:{' '}
            <span className="loggedin-res">
              {visitor.registered
                ? `${moment(visitor.lastLoggedIn).format(
                    'MMMM Do YYYY, h:mm:ss a'
                  )}`
                : 'Never'}
            </span>{' '}
          </h2>
        </div>
      </div>
      {showDetails && (
        <div className="vd-wrapper">
          <div className="vd-login-count">
            {visitor.registered && <h1>Login count: {visitor.logginCount}</h1>}
          </div>
          <div className="vd-ticket_box">
            <h1 className="vd-ticket-no">{ticketNo}</h1>
            {ticketNo === 'No Ticket Assigned' ? (
              <Button
                title="assign ticket"
                onClick={() => rollTicket(visitor.id)}
              />
            ) : (
              <div>
                <Button
                  title={emailStatus}
                  onClick={() => resendTicket(visitor.id)}
                />
                <Button
                  title="roll ticket"
                  onClick={() => rollTicket(visitor.id)}
                />
                {!isSuspended ? (
                  <Button
                    className="button-danger"
                    title="suspend ticket"
                    onClick={() => suspend(visitor.id)}
                  />
                ) : (
                  <Button
                    className="button-warning"
                    title="unsuspend ticket"
                    onClick={() => unsuspend(visitor.id)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default VisitorItem;
